.payment_card
{
    background: #fff;
    padding: 0;
    width: 100%;
    float: left;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    padding: 20px;
    font-size: 14px;
    letter-spacing: 2px;
    color: #666666;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

