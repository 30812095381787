.fc-timeGridWeek-view .fc-widget-content,.fc-timeGridDay-view .fc-widget-content
{
    height: 14px !important;   
    position: relative;
}

.fc-time span
{
    font-size: 12px !important;
    position: absolute;
    top: 0;
    right: 5px;
    text-align: right;
    width: 70px;
    font-weight: bold !important;
}
.fc-day-header span
  {
    line-height: 30px;
    letter-spacing: 0px;
    font-size: 12px;
    text-align: center;
    font-weight: bold !important;
  }
  .fc-view
  {
    background-color: white;
  }
  .calendar-title
  {
    font-size: 24px !important;
    font-weight: 600;
    margin-left: 0px !important;
    text-align: left;
    margin-right: 10px;
    color: black;
    line-height: 38px;
  }
  .fc-day-number
  {
      padding: 10px;
      font-size: 12px;
  }
  .fc-dayGridMonth-view .fc-content, .fc-timeGridWeek-view  .fc-content
  {
      padding:5px;
      color: black;
      font-weight: 600;
  }
  .fc-timeGridWeek-view .fc-content .fc-time span, .fc-dayGridMonth-view .fc-content .fc-time
  {
    position: inherit;
    font-weight: 600 !important;
  }
  

.fc-event.cancelled_booking,
.fc-event.cancelled_booking
{
	background-color: #a52a2a;
}


.fc-event.payment_pending_booking,
.fc-event.payment_pending_booking,
.fc-event.payment_pending_booking,
.fc-event.payment_pending_booking   {
	background-color: #C1F4CA !important; color: #000;
}

.fc-event.confirmed-event,
.fc-event.confirmed-event:hover,
.fc-event.confirmed_other_booking,
.fc-event.confirmed_other_booking:hover  {
	
	background-color: #DEF7C1;
	
}




.fc-event.confirm_pause,
.fc-event.confirm_pause,
.fc-event.confirm_pause,
.fc-event.confirm_pause
{   
	background-color: #F8EEBF !important; color: #000;
}

.fc-event.confirm_pause:hover,
.fc-event.confirm_pause:hover,
.fc-event.confirm_pause:hover,
.fc-event.confirm_pause:hover{   
	background-color: #F6E7A0 !important;
}

.fc-event.temp_booking {   
	background-color: #ffaff4;
}



.fc-event.declined_disputed_booking,
.fc-event.declined_disputed_booking
 {
	background-color: #ff0000;
}

.fc-event.ppc_method {   
  border-color: #ffa500;
  border-width: 5px;
}
.fc-event.payg_method {
  border-color: #ffff00;
  border-width: 5px;
}
.fc-event.other_method   {
  border-color: #000000;
  border-width: 5px;
}




.fc-event.temp-booked
{
  color: #C7B299;
}

.fc-event {
  text-align: left;
  background: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #979797;
  padding-top: 5px;
  padding-left: 5px;
}
.fc-event:hover {
  cursor: pointer;
  /*box-shadow: 0 2px 20px 0 #d5d5d6;*/
}
.simpletable
{
  border-width: 0;
  border-color: rgba(0,0,0,0);
}
.simpletable tr td,.simpletable tr th
{
  padding: 14px;
  border-color: #eeeeee;
  color: black;
  font-weight: 600;
}


.fc-header-toolbar
{
  display: flex;
  justify-content: center;
}
.fc-right,.fc-center
{
  display: none;
}
.fc-left h2
{
  font-size: 20px;
}